<template>
<div>
    <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item>1</van-swipe-item>
        <van-swipe-item>2</van-swipe-item>
    </van-swipe>
</div>
</template>
<script>
export default {
    data(){
        return {
           
        }
    },
    components:{},
    watch:{},
    mounted(){},
    methods:{}
}
</script>
<style lang="stylus"></style>